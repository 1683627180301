import React from "react";

const Footer = () => {
  return (
    <footer>
      <b>
        ©2022 by AJ van Rensburg Ltd. Attorneys VRB Law.
        <br /> Created and Maintained by{" "}
        <a href="https://www.rutech.co.za/">RuTech Solutions</a>
      </b>
    </footer>
  );
};

export default Footer;
