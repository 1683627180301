import React from "react";

const About = () => {
  return (
    <div className="container">
      <div className="about-us">
        <h2 className="center-text">About Us</h2>
        <hr />
      </div>
      <p>
        AJ Van Rensburg Attorneys Inc., established, registered and incorporated
        in 2007, is a vibrant boutique law firm situated at 2 Cardigan Road,
        Parkwood, Johannesburg.
      </p>
      <p>
        Our convenient location allows practical and effective access to the
        Johannesburg High Court, the Constitutional Court and falls within the
        jurisdiction of the Randburg and Johannesburg Magistrate’s Courts. Our
        firm’s expansive network of excellent correspondents assists us in
        effectively handling matters across the country and abroad.
      </p>
      <p>
        AJ Van Rensburg Attorneys Inc. specializes in Community Residential Law,
        more specifically legal matters pertaining to Bodies Corporate, Common
        Law communal entities, Homeowners Associations created as Non-Profit
        Companies, Share Block Schemes and Associations created by the Land Use
        Proclamation Ordinance.
      </p>
      <p>
        We currently provide professional legal services to approximately 400
        (four hundred) Bodies Corporate and 72 (seventy-two) Homeowners
        Associations. The details of these homeowners’ associations, are set out
        hereinunder.
      </p>
      <p>
        As from September 2012, we are the preferred legal service provider by
        both ARC (Association of Residential Communities) and CAISA NPC
        (Community Association Institute of South Africa NPC) and as such we
        have aligned ourselves in a close working relationship with both
        Associations and via these with the Community Schemes Ombud Service.
      </p>
      <p>
        We understand and empathise with the various difficulties businesses and
        individuals face on a day-to-day basis and in extension to the legal
        services provided and the strain that the South African law processes
        can place on them.
      </p>
      <p>
        Therefore, since the inception of AJ Van Rensburg Attorneys in 2007, our
        dynamic team has strived to provide our clients with the best legal
        services at the most competitive rates. We are able to accomplish this
        by, years of experience, maintaining excellent client relationships and
        aligning our services to our clients’ individual needs wherefore our
        clients can depend on fast, tailor-made and effective results.{" "}
      </p>
      <p>
        Our client base includes both domestic and international community
        residential scenarios, ranging from anticipated developments, young
        developments with a still active developer, to well established
        communities to be regulated.
      </p>
    </div>
  );
};

export default About;
