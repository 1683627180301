import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import VRBLaw from "../resources/vrb-law.PNG";

const NavigationBar = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={VRBLaw} height="150" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="/our-people">Our People</Nav.Link>
            <Nav.Link className="dont">|</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="/debt-collection-services">
                Debt Collection Services
              </NavDropdown.Item>
              <NavDropdown.Item href="/property-law">
                Property Law
              </NavDropdown.Item>
              <NavDropdown.Item href="/amalgation-of-non-profit-companies">
                Amalgamation of Non-Profit Companies
              </NavDropdown.Item>
              <NavDropdown.Item href="/alternative-dispute-resolution">
                Alternative Dispute Resolution
              </NavDropdown.Item>
              <NavDropdown.Item href="/client-relationship-management">
                Client Relationship Management
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="dont">|</Nav.Link>
            <Nav.Link href="#contact-us">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
