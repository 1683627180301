import React from "react";

const Header = () => {
  return (
    <div className="header">
      <div className="heading-height">
        <hr />
        <h1 className="center-text">Welcome to VRB Law</h1>
        <hr />
        <h4 className="center-text">
          <em>"Omnia mutantur nos et mutamur in illis"</em>
        </h4>
        <h5 className="center-text">
          <em>All things change and we change with them</em>
        </h5>
      </div>
      <div className="row height-block">
        <div className="col-md-12 col-lg-6">
          <div className="row">
            <div className="col-md-12 col-lg-6 accounting"></div>
            <div className="col-md-12 col-lg-6 law-word"></div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-4 law-balance"></div>
            <div className="col-md-12 col-lg-4">
              <div className="col-md-12 row one three-height"></div>
              <div className="col-md-12 row two three-height"></div>
            </div>
            <div className="col-md-12 col-lg-4 relationship"></div>
          </div>
        </div>
        <div className="blue col-md-12 col-lg-6">
          <div className="row">
            <div className="col-lg-2">
              <div className="col-md-12 row three two-height"></div>
              <div className="col-md-12 row one two-height"></div>
              <div className="col-md-12 row two two-height"></div>
            </div>
            <div className="col-md-12 col-lg-10 law"></div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-8 three one-height"></div>
            <div className="col-md-12 col-lg-4 one one-height"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
