import React from "react";
import Debt from "../resources/Debt.jpg";

const DebtCollectionServices = () => {
  return (
    <section className="debt-collection container">
      <div className="row">
        <div className="col-md-2">
          <div className="lighter"></div>
        </div>
        <div className="col-md-2">
          <div className="darker"></div>
        </div>
        <div className="col-md-4 services-headers">
          <img src={Debt} alt="Debt Collection" className="debt-image" />
          <div className="text">
            <hr />
            <h1>
              Debt <br />
              Collection
              <br /> Services
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-md-2">
          <div className="darker"></div>
        </div>
        <div className="col-md-2">
          <div className="lighter"></div>
        </div>
      </div>
      <div>
        <div className="services-text">
          <p>
            We pride ourselves on our genuine understanding of each of our
            clients’ individual needs, wherefore we are able to effectively and
            efficiently provide them with tailor-made debt collection services
            at the most competitive rates. Our attorneys’ comprehensive
            knowledge and considerable experience in debt collections include
            all types of clients, including but not limited to, Companies;
            Individuals; Bodies Corporate; Homeowners Associations and self-
            insured Transport Companies
          </p>
          <p>
            Some of our commercial clients include Fuelex (Pty) Ltd, Reinhardt
            Transport Group (Pty) Ltd, Chrome Carriers (Pty) Ltd, PH Projects
            (Pty) Ltd, Croc Crane Hire (Pty) Ltd, MacLabour CC, Transmac
            Transport (Pty) Ltd and Controlgear (Pty) Ltd.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DebtCollectionServices;
