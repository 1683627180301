import React from "react";
import Property from "../resources/property-law.jpg";

const PropertyLaw = () => {
  return (
    <section className="property-law container">
      <div className="row">
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-4 services-headers">
          <img src={Property} alt="Debt Collection" className="debt-image" />
          <div className="text">
            <hr />
            <h1>
              Property
              <br /> Law
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
      </div>
      <div>
        <div className="services-text">
          <p>
            Communal Residential Law being our specialty, we provide our clients
            with general legal assistance and legal guidance on complex legal
            disputes in respect of non-profit communal entities such as
            Homeowners Associations, either created as a Non-Profit Company
            (“NPC”) or share block schemes or Homeowners Association created by
            the Land Use Proclamation Ordinance.
          </p>
          <p>
            Our legal assistance to these Associations also includes, but is not
            limited to, normal levy collections for Bodies Corporate /
            Homeowners Associations, review of the Rules or Memorandum of
            Incorporation, to more complex issues such as dispute resolution in
            Communal Residential legal structures.
          </p>
          <p>
            We further assist the Associations, as Non-Profit Companies, to
            draft their Memoranda of Incorporation (in substitution of their
            existing Articles of Association and Memoranda of Association)
            conforming to the provisions of the new Companies Act, Act 71 of
            2008 where-after we assist them to duly accept the Memoranda of
            Incorporation at Special General Meetings and subsequently
            successfully lodge same with the Companies and Intellectual Property
            Commission (“CIPC”) for approval resulting in a legally enforceable
            Memoranda of Incorporation.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PropertyLaw;
