import React from "react";
import Amalgation from "../resources/amalgation.jpg";

const Amalgamtion = () => {
  return (
    <section className="property-law container">
      <div className="row">
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-4 services-headers">
          <img src={Amalgation} alt="Debt Collection" className="debt-image" />
          <div className="text">
            <hr />
            <h1>
              Amalgamation <br />
              of Non-Profit
              <br /> Companies
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
      </div>
      <div>
        <div className="services-text">
          <p>
            We have to date provided on two occasions legal assistance to
            Non-Profit Companies to amalgamate in terms of the provisions of the
            Companies Act of 2008. Our services included from drafting the
            appropriate notices convening a Special General meeting for members
            to resolve upon the amalgamation, attending the meeting answering
            legal questions to processing the amalgamation at the Companies and
            Intellectual Commission.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Amalgamtion;
