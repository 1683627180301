import React from "react";
import ContactForm from "./ContactForm";
import { GeoAltFill, TelephoneFill, PeopleFill } from "react-bootstrap-icons";

const Contact = () => {
  return (
    <div id="contact-us" className="container about-us">
      <h2 className="center-text">Contact Us</h2>
      <hr />
      <div className="row contact-row">
        <div className="col-md-8">
          <ContactForm></ContactForm>
        </div>
        <div className="col-md-4 contact-details">
          <span>
            <GeoAltFill /> 2 Cardigan Road, Parkwood, Johannesburg
          </span>
          <span>PO Box 72109, Parkview, 2122</span>
          <br />
          <b>
            <TelephoneFill /> Tel:
          </b>
          (011) 447 3034/5143/6017/6417/7358
          <br />
          <br />
          <b>
            <TelephoneFill /> Fax:
          </b>
          (011) 447 0419/(086) 234 6541 [Direct]
        </div>
      </div>
    </div>
  );
};

export default Contact;
