import React from "react";
import Relationship from "../resources/handshake.jpg";

const CRM = () => {
  return (
    <section className="property-law container">
      <div className="row">
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-4 services-headers">
          <img
            src={Relationship}
            alt="Debt Collection"
            className="debt-image"
          />
          <div className="text">
            <hr />
            <h1>
              Client
              <br /> Relationship
              <br /> Management
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
      </div>
      <div>
        <div className="services-text">
          <p>
            We pride ourselves on our reputation of always providing excellent
            appropriate legal services to our clients. And as such an attorney
            is assigned as the Relationship Manager to a client. It is the duty
            of the Relationship Manager to ensure that our team meets the
            client’s expectations in respect of turn-around times, service
            delivery levels and quality of work. The Relationship Manager will
            further liaise with the client and according to feedback received
            realign the team’s priorities and if necessary, ensure that remedial
            steps are taken, or improvements effected.
          </p>
          <p>
            We may ask an official of a client to spend a little time during
            consultations with the Relationship Manager so as to help in our
            effort to maintain the best levels of service delivery possible. If
            a client has any queries or concerns about the work which we
            undertake for them, those queries can be raised immediately with the
            Relationship Manager.
          </p>
          <p>
            As part of our skillset, our experience includes the drafting and
            settling of submissions, on our clients’ behalf, to the Community
            Scheme Ombud Service (“CSOS”) or even successfully moving
            applications to set aside adverse CSOS Adjudicator awards, seeing to
            it that justice prevails.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CRM;
