import React from "react";
import Law from "../resources/law-1.jpg";

const AlternativeDisputeResolution = () => {
  return (
    <section className="property-law container">
      <div className="row">
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-4 services-headers">
          <img src={Law} alt="Debt Collection" className="debt-image" />
          <div className="text">
            <hr />
            <h1>
              Alternative <br />
              Dispute
              <br /> Resolution
            </h1>
            <hr />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="darker"></div>
        </div>
        <div className="col-sm-2">
          <div className="lighter"></div>
        </div>
      </div>
      <div>
        <div className="services-text">
          <p>
            As a cost effective, time effective and purposeful alternative to
            traditional litigation, we offer comprehensive alternative dispute
            resolution interventions and legally guide our clients on the most
            effective and appropriate mechanism applicable to the specific
            situation.
          </p>
          <p>
            Often, as part of the review of a Memorandum of Incorporation, it is
            our recommendation that our clients incorporate internal dispute
            resolution mechanisms, which avoids costly future litigation
            resulting in having matters resolved internally.
          </p>
          <p>
            As part of our skillset, our experience includes the drafting and
            settling of submissions, on our clients’ behalf, to the Community
            Scheme Ombud Service (“CSOS”) or even successfully moving
            applications to set aside adverse CSOS Adjudicator awards, seeing to
            it that justice prevails.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AlternativeDisputeResolution;
