import React from "react";
import Judge from "../resources/judge.jpg";
import People from "../resources/people.jpg";
import Handshake from "../resources/handshake.jpg";

const OurPeople = () => {
  return (
    <div className="our-people container">
      <div className="center-text-center">
        <h1>Our People</h1>
        <hr />
        <br />
      </div>
      <div className="row our-people-row">
        <div className="col-sm-3 center-text-center">
          <img src={People} alt="Our People" className="smaller-image" />
        </div>
        <div className="col-sm-6 center-text-center">
          <img src={Judge} alt="Our People" className="larger-image" />
        </div>
        <div className="col-sm-3 center-text-center">
          <img src={Handshake} alt="Our People" className="smaller-image" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1 dotted"></div>
        <div className="col-sm-11 dot-next">
          <p>
            Our highly skilled, professional and motivated team aspires to
            provide prompt, effective and productive legal service to our
            clients on a cost-efficient basis.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1 dotted"></div>
        <div className="col-sm-11 dot-next">
          <p>
            We have a staff complement of 11 individuals dedicated to providing
            legal services to the client to collect arrear levies in a speedy
            and cost-effective manner and other legal professional services
            falling within our field of expertise. Our legally trained staff
            members have more than 27 (twenty-seven) years of legal experience
            in Body Corporate- and Homeowners Association-related matters and
            are all inclusive of our hourly tariff.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1 dotted"></div>
        <div className="col-sm-11 dot-next">
          <p>
            Our experience ranges from standard levy collection services to more
            complicated matters such as Dispute Resolution in Communal
            Residential legal structures. Recently our professional attorneys
            have been requested to act as independent chair for members’
            meetings which requires for various reasons a presiding experienced
            person on the process to have it conducted fairly, efficiently and
            impartially and reach a resolution. Our team consists of three
            qualified attorneys, two paralegals and one candidate attorney
            complemented by highly knowledgeable and skilled supporting staff
            members.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1 dotted"></div>
        <div className="col-sm-11 dot-next">
          <p>
            Our attorneys also specialise in corporate and commercial law with
            our main focus being Community Residential Law. Our mission is to
            ensure that both our professional and supporting staff stay abreast
            of the developments in their field in the Law and service our
            clients accordingly.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1 dotted"></div>
        <div className="col-sm-11 dot-next">
          <p>
            As AJ Van Rensburg Attorneys Inc. is passionate about the
            development and honing of legal skills, our firm is listed at
            universities and offer both developmental positions for candidate
            attorneys and sponsor academic prizes for top achievers and is often
            invited to purposeful participate and engage with legal academia.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurPeople;
