import "./App.css";
import Header from "./components/header";
import NavigationBar from "./components/navbar";
import About from "./components/about";
import Contact from "./components/contact";
import Footer from "./components/footer";
import DebtCollectionServices from "./components/debt-collection-services";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Amalgamtion from "./components/amalgamation-of-non-profit-companies";
import PropertyLaw from "./components/property-law";
import CRM from "./components/crm";
import OurPeople from "./components/our-people";
import AlternativeDisputeResolution from "./components/alternative-dispute-resolution";

function App() {
  return (
    <div>
      <NavigationBar></NavigationBar>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div>
                <Header></Header>
                <About></About>
              </div>
            }
          />
          <Route exact path="/our-people" element={<OurPeople></OurPeople>} />
          <Route
            exact
            path="/debt-collection-services"
            element={<DebtCollectionServices></DebtCollectionServices>}
          />
          <Route
            exact
            path="/property-law"
            element={<PropertyLaw></PropertyLaw>}
          />
          <Route
            exact
            path="/amalgation-of-non-profit-companies"
            element={<Amalgamtion></Amalgamtion>}
          />
          <Route
            exact
            path="/alternative-dispute-resolution"
            element={
              <AlternativeDisputeResolution></AlternativeDisputeResolution>
            }
          />
          <Route
            exact
            path="/client-relationship-management"
            element={<CRM></CRM>}
          />
          <Route
            exact
            path="*"
            element={
              <div>
                <h1>Page not found</h1>
              </div>
            }
          />
        </Routes>
      </Router>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
